/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import Navigation from "./components/Navigation";
import HeroSection from "./components/sections/HeroSection";
import StorySection from "./components/sections/StorySection";
import WhyFijoliSection from "./components/sections/WhyFijoliSection";
import WhatsInBookSection from "./components/sections/WhatsInBookSection";
import WhoShouldReadFijoli from "./components/sections/WhoShouldReadFijoli";
import CTASection from "./components/sections/CTASection";
import Footer from "./components/Footer";
import { FaWhatsapp } from 'react-icons/fa';
import ReviewsSection from "./components/sections/ReviewSection";

function App() {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  const setSectionRef = (element, index) => {
    sectionsRef.current[index] = element;
  };

  return (
    <div className="bg-white">
      <Navigation />
      
      {/* Promotional Banner */}
      {/* {showBanner && (
        <div className="relative bg-red-600 text-white py-2 z-10">
          <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
            <p className="text-sm md:text-base">
              Upto 70% discounts on bulk ordering for Schools and Corporates
            </p>
            <button
              onClick={() => setShowBanner(false)}
              className="text-white hover:text-gray-200 focus:outline-none"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )} */}

      <HeroSection setSectionRef={setSectionRef} index={0} />
      <StorySection setSectionRef={setSectionRef} index={1} />
      <WhyFijoliSection setSectionRef={setSectionRef} index={2} />
      <WhoShouldReadFijoli setSectionRef={setSectionRef} index={3} />
      <WhatsInBookSection setSectionRef={setSectionRef} index={4} />
      <CTASection setSectionRef={setSectionRef} index={5} />
      {/* <ReviewsSection setSectionRef={setSectionRef} index={6} /> */}
      <Footer />

      {/* Floating Whatsapp Button */}
      <div className="fixed bottom-8 right-8 flex flex-col items-end gap-2 z-50">
        <div className="bg-red-600 text-white px-4 py-2 rounded-lg text-xs font-semibold animate-highlight-blink text-center">
          <div className="animate-blink">Avail early bird offer</div>
          <div className="animate-blink" style={{ animationDelay: '1s' }}>Up to 70% discount on bulk ordering</div>
        </div>
        <a
          href="https://wa.me/+919008023230"
          className="bg-[#25D366] text-white p-4 rounded-full shadow-lg hover:bg-[#25D366] transition-colors hover-scale"
        >
          <FaWhatsapp className="w-6 h-6" />
        </a>
      </div>
    </div>
  );
}

export default App;