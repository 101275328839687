import React, { useState } from 'react';
import { FaWhatsapp, FaInstagram, FaBook, FaPlay, FaTimes } from 'react-icons/fa';
import bookImage from '../../assets/book2.jpeg';
import videoFile from '../../assets/video.mov';

const CTASection = ({ setSectionRef, index }) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <section ref={(el) => setSectionRef(el, index)} id="cta" className="py-10 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl font-bold text-red-600 mb-4 font-poppins animate-blink">
            Ready to Transform Your Life?
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto font-lato">
            Join thousands of successful individuals who have transformed their lives with Fijoli.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 items-start">
          {/* Left Column - Content */}
          <div className="space-y-8">
            {/* Benefits */}
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-6 font-poppins">
                Those who own Fijoli can avail:
              </h3>
              <ul className="space-y-4">
                <li className="flex items-center p-4 bg-gray-50 rounded-lg">
                  <span className="text-gray-900 text-xl mr-3">✓</span>
                  <span className="text-gray-700 font-lato">FREE diet consultation</span>
                </li>
                <li className="flex items-center p-4 bg-gray-50 rounded-lg">
                  <span className="text-gray-900 text-xl mr-3">✓</span>
                  <span className="text-gray-700 font-lato">Up to 50% discount on "Live Online Personal Fitness Training"</span>
                </li>
              </ul>
            </div>

            {/* Book Info */}
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4 font-poppins">
                Book Specifications
              </h3>
              <p className="text-gray-700 font-lato bg-gray-50 p-4 rounded-lg">
                It's Light weight, Matt finished, Beautiful cover, Easy to carry, Pocket friendly, hardly 300 grams (126 pages).
              </p>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row gap-4">
              <a
                href="https://wa.me/+919008023230"
                className="inline-flex items-center justify-center px-6 py-3 rounded-lg font-semibold bg-gray-900 text-white hover:bg-gray-800 transition-all transform hover:scale-105 font-poppins"
              >
                <FaWhatsapp className="mr-2 text-xl" />
                Connect with Fijoli
              </a>
              <a
                href="https://instagram.com/fijoli_fitness_book"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-6 py-3 rounded-lg font-semibold bg-gray-900 text-white hover:bg-gray-800 transition-all transform hover:scale-105 font-poppins"
              >
                <FaInstagram className="mr-2 text-xl" />
                Book with the basics of Fitness
              </a>
            </div>

            {/* Sales Notice */}
            {/* <div className="bg-red-600 text-white p-4 rounded-lg text-center font-semibold">
              Up to 70% discounts on bulk ordering for Schools and Corporates
            </div> */}
          </div>

          {/* Right Column - Media */}
          <div className="space-y-8">
            <div className="relative group cursor-pointer" onClick={() => setShowVideo(true)}>
              <div className="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden shadow-lg">
                <img
                  src={bookImage}
                  alt="Fijoli Book"
                  className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                />
                {/* Play Button Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-40 group-hover:bg-opacity-50 transition-all duration-300 flex items-center justify-center">
                  <div className="w-20 h-20 bg-white rounded-full flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                    <FaPlay className="text-gray-900 text-3xl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Video Modal */}
      {showVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4">
          <div className="relative w-full max-w-4xl">
            <button
              onClick={() => setShowVideo(false)}
              className="absolute -top-12 right-0 text-white hover:text-gray-300 transition-colors"
            >
              <FaTimes className="text-3xl" />
            </button>
            <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
              <video
                src={videoFile}
                controls
                autoPlay
                className="w-full h-full"
                title="Fijoli Book Introduction"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default CTASection; 