import React from 'react';
import { colors } from '../../config/colors';
import bookCover from '../../assets/book.jpg';

const WhyFijoliSection = ({ setSectionRef, index }) => {
  return (
    <section ref={(el) => setSectionRef(el, index)} id="why-fijoli" className="py-10 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl font-bold text-red-600 mb-4 font-poppins">
            Why Fijoli?
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto font-lato">
          Understand the fundamentals to live healthy - 
          <span className="text-gray-600 font-lato font-bold italic">
          FIJOLI has step by step guide to your fitness / nutrition
            </span>
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-12 items-center mb-8">
          <div className="relative">
            <div className="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden shadow-xl">
              <img
                src={bookCover}
                alt="Fijoli Book Cover"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="space-y-8">
          <p className="text-l text-gray-600 max-w-3xl mx-auto font-lato">
            Fijoli has been reviewed and acknowledged by Certified Fitness Trainer, Sports Nutritionist/Dietician, Athlete, Engineers, Doctors - General Physician, Pulmonologist, Physiotherapist, Anesthesiologist Critical Care - Intensivist and other professionals.
          </p>
            <div className="bg-gray-100 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-gray-900 mb-4 font-poppins">
                Comprehensive Fitness Guide
              </h3>
              <ul className="">
                <li className="flex items-start">
                  <span className="text-2xl mr-2 text-red-600">•</span>
                  <span className="text-gray-600  font-lato">Answers hundreds of common fitness queries</span>
                </li>
                <li className="flex items-start">
                  <span className="text-2xl text-red-600 mr-2">•</span>
                  <span className="text-gray-600 font-lato">Unveils hundreds of fitness concepts and debunks myths (including woman's fitness)</span>
                </li>
                <li className="flex items-start">
                  <span className="text-2xl text-red-600 mr-2">•</span>
                  <span className="text-gray-600 font-lato">Has every detail on both workout and diet (step by step guide for weight loss/gain. You will never need a dietician again)</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-gray-900 mb-4 font-poppins">
            Anyone looking to understand the fundamentals of fitness and nutrition
            </h3>
            <p className="text-gray-600 font-lato">
            This book breaks down complex concepts into simple, actionable knowledge for beginners.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-gray-900 mb-4 font-poppins">
            Those who have just started their fitness journey
            </h3>
            <p className="text-gray-600 font-lato">
            Whether you are hitting the gym, starting a sport, or simply becoming more active, Fijoli is the perfect guide to help you build a strong foundation on the fundamentals </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-gray-900 mb-4 font-poppins">
            Individuals aiming to reach their fitness goals through a structured diet
            </h3>
            <p className="text-gray-600 font-lato">
            Learn how to align your eating habits with your fitness objectives using science-backed strategies and easy-to-follow individual specific calculations.
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default WhyFijoliSection; 