/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { FaUserAlt, FaSignInAlt } from 'react-icons/fa';
import logo from '../assets/logo.jpg';
import { colors } from '../config/colors';

function Navigation() {
    const [nav, setNav] = useState(false)
    
    const handleClick = () => setNav(!nav)

    const handleClose = () => {
      setNav(false);
    };

  return (
    <div className="w-screen h-[70px] z-20 fixed bg-white shadow-md">
      <div className='px-4 flex justify-between items-center w-full h-full max-w-7xl mx-auto'>
        <div className='flex items-center'>
          {/* <img src={logo} alt="Fijoli Logo" className='h-12 w-auto mr-4' /> */}
          <span className={`text-red-600  text-4xl font-bold font-poppins hidden md:flex`}>FIJOLI</span>
        </div>

        {/* Centered Fijoli Text for Mobile */}
        <div className='absolute left-1/2 transform -translate-x-1/2 md:hidden'>
          <span className={`text-red-600  text-4xl font-bold font-poppins`}>FIJOLI</span>
        </div>

        {/* Centered Navigation Menu */}
        <div className='hidden md:flex items-center justify-center flex-1'>
          <ul className='flex items-center space-x-8'>
            <li className='cursor-pointer transition-colors'>
              <a href="#home" className={`text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Home</a>
            </li>
            <li className='cursor-pointer transition-colors'>
              <a href="#story" className={`text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Story</a>
            </li>
            <li className='cursor-pointer transition-colors'>
              <a href="#why-fijoli" className={`text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Why Fijoli</a>
            </li>
            <li className='cursor-pointer transition-colors'>
              <a href="#whats-in-book" className={`text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>What's in the Book</a>
            </li>
            <li className='cursor-pointer transition-colors'>
              <a href="#who-should-read-fijoli" className={`text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Who should read Fijoli</a>
            </li>
          </ul>
        </div>

        {/* Order Now Button */}
        <div className='hidden md:flex items-center'>
          <a
            href="https://wa.me/+919008023230"
            className={`flex items-center px-8 py-3 rounded-full text-lg font-semibold border border-red-600 text-${colors.text.light} hover:bg-${colors.primary.dark} transition-all transform hover:scale-105 shadow-lg hover:shadow-xl font-poppins`}
          >
            <span className="text-base">Order Now</span>
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className='md:hidden' onClick={handleClick}>
          {!nav ? (
            <MenuIcon className={`w-6 text-${colors.text.dark}`} />
          ) : (
            <XIcon className={`w-6 text-${colors.text.dark}`} />
          )}
        </div>
      </div>

      {/* Mobile Navigation */}
      <ul className={!nav ? 'hidden' : `absolute bg-white w-full px-4 py-4 top-[70px] md:hidden shadow-lg`}>
        <li onClick={handleClose} className={`py-2 border-b border-${colors.border.light}`}>
          <a href="#home" className={`block text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Home</a>
        </li>
        <li onClick={handleClose} className={`py-2 border-b border-${colors.border.light}`}>
          <a href="#story" className={`block text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Story</a>
        </li>
        <li onClick={handleClose} className={`py-2 border-b border-${colors.border.light}`}>
          <a href="#why-fijoli" className={`block text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Why Fijoli</a>
        </li>
        <li onClick={handleClose} className={`py-2 border-b border-${colors.border.light}`}>
          <a href="#whats-in-book" className={`block text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>What's in the Book</a>
        </li>
        <li onClick={handleClose} className={`py-2 border-b border-${colors.border.light}`}>
          <a href="#who-should-read-fijoli" className={`block text-${colors.text.dark} hover:text-${colors.primary.main} font-lato`}>Who should read Fijoli</a>
        </li>
        <li onClick={handleClose} className='py-4'>
          <a
            href="https://wa.me/+919008023230"
            className={`block text-center px-8 py-3 border border-red-600 text-${colors.text.light} rounded-full hover:bg-${colors.primary.dark} transition-all transform hover:scale-105 shadow-lg hover:shadow-xl font-poppins font-semibold`}
          >
            Order Now
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Navigation 