import React from 'react';
import { FaDumbbell, FaAppleAlt, FaBookMedical, FaGraduationCap, FaHeart, FaBriefcase } from 'react-icons/fa';

const WhatsInBookSection = ({ setSectionRef }) => {
  const chapters = [
    {
      title: "Lifestyle Guidance",
      description: "It provides lifestyle guidance on fitness, diet, sleep, socializing, and managing stress — crucial topics for young adults transitioning into professional life.",
      icon: <FaHeart className="w-8 h-8" />
    },
    {
      title: "Corporate Wellness",
      description: "The book addresses the adverse effects of inactivity, stress, binge eating, alcohol, and lack of sleep —common in corporate and high-pressure jobs. It offers practical, science-based fitness and wellness advice that can easily fit into a busy lifestyle.",
      icon: <FaBriefcase className="w-8 h-8" />
    },
    {
      title: "Comprehensive Guide",
      description: "Breaks-down anatomy, nutrition, muscle types, training science, supplements, injury and recovery, woman's fitness, hormones, common myths, personal hygiene, all common fitness queries. The book is ideal for beginners who want to understand how their body works.",
      icon: <FaDumbbell className="w-8 h-8" />
    },
    {
      title: "Transformation Guide",
      description: "List of high protein natural foods. Step by Step calculations for every individuals body index. Complete guide for transformation - weight loss, weight gain, maintenance.",
      icon: <FaAppleAlt className="w-8 h-8" />
    },
    {
      title: "Doctors Acknowledgement",
      description: "Fijoli book has been reviewed and acknowledged by Certified FitnessTrainer, Sports Nutritionist /Dietician, Athlete, Engineers, Doctors - General Physician, Pulmonologist, Physiotherapist, Anesthesiologist Critical Care - Intensivist and other professionals",
      icon: <FaBookMedical className="w-8 h-8" />
    },
    {
      title: "Students Magic Wand",
      description: "In few years, students will graduate and enter workforce, facing high job pressures that can negatively impact health. Empowering young minds with fitness knowledge is the first step towards healthier nation. Fijoli teaches the fundamentals and well being, which is just as important as academic subjects",
      icon: <FaGraduationCap className="w-8 h-8" />
    }
  ];

  return (
    <section id="whats-in-book" ref={(el) => setSectionRef(el, 4)} className="py-10 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-red-600 mb-3 font-poppins">
            What's in the Book?
          </h2>
          <p className="text-base text-gray-600 max-w-2xl mx-auto leading-relaxed">
            A Comprehensive Guide to Transforming Your Life Through Fitness and Nutrition
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {chapters.map((chapter, index) => (
            <div
              key={index}
              className="bg-white rounded-xl p-4 shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 border border-gray-100"
            >
              <div className="flex flex-col items-center text-center">
                <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center mb-3">
                  <div className="text-red-600 transform hover:scale-110 transition-transform duration-300">
                    {chapter.icon}
                  </div>
                </div>
                <h3 className="text-lg font-semibold text-gray-900 font-poppins mb-2">
                  {chapter.title}
                </h3>
                <p className="text-sm text-gray-600 text-left leading-relaxed">
                  {chapter.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatsInBookSection; 