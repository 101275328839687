export const colors = {
  primary: {
    main: '#DC2626', // red-600
    light: '#FCA5A5', // red-300
    dark: '#B91C1C', // red-700
    hover: '#FEE2E2', // red-50
  },
  text: {
    light: '#FFFFFF',
    dark: '#1F2937', // gray-800
    muted: '#6B7280', // gray-500
  },
  background: {
    light: '#FFFFFF',
    dark: '#111827', // gray-900
    transparent: 'transparent',
  },
  border: {
    light: '#E5E7EB', // gray-200
    dark: '#374151', // gray-700
  },
  scrollbar: {
    track: '#F3F4F6', // gray-100
    thumb: '#D1D5DB', // gray-300
    hover: '#9CA3AF', // gray-400
  }
}; 