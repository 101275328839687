import React from 'react';
import storyImage from '../../assets/story.jpg';

const StorySection = ({ setSectionRef, index }) => {
  return ( 
      <section ref={(el) => setSectionRef(el, index)} id="story" className="py-10 bg-gray-50 border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl font-bold text-red-600 mb-4 font-poppins">
          How life changes after College?
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto font-lato">
            A short story…          
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <p className="text-lg text-gray-600 leading-relaxed font-lato">
            Fiji was one of the most active students in college. He held the record for the fastest run on sports day and excelled in volleyball, even representing his college at district tournaments.
            </p>
            <p className="text-lg text-gray-600 leading-relaxed font-lato">
            After graduation, life took a different turn. The stress of job hunting left him anxious and questioning his self-worth.
            A year later, he finally landed a well-paying job. However, the demands of his new role brought unhealthy habits—late nights, binge eating, weekend parties, and even occasional starvation to meet deadlines. </p>
            <p className="text-lg text-gray-600 leading-relaxed font-lato">
            One winter, Fiji fell ill. While sitting in a clinic waiting room, he realized it was the first time in two years he had stopped to reflect. He thought about his college days and how much his life had changed.
            When his token number was called, he struggled to get up from his seat. After undergoing several tests, the results were sobering. He had gained 24 kg, his cholesterol levels were dangerously high, and he was on the verge of diabetes. His health had deteriorated alarmingly.</p>
          </div>
          <div className="relative">
            <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden shadow-xl">
              <img
                src={storyImage}
                alt="Fiji's Story"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
        <div className="text-center mt-16">
          <h2 className="text-2xl md:text-2xl font-bold text-red-600 mb-4 font-poppins">
          Life can change quickly
          </h2>
          <p className="text-xl italic text-gray-600 max-w-3xl mx-auto font-lato">
          "Regular physical activity, a well-balanced diet, adequate sleep, and happiness are crucial to maintaining overall health and are the essential components of Preventive Care."         
          </p>
        </div>

      </div>
    </section>
  );
}

export default StorySection; 