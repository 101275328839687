import React from 'react';

const WhoShouldReadFijoli = ({ setSectionRef, index }) => {
  return ( 
      <section ref={(el) => setSectionRef(el, index)} id="who-should-read-fijoli" className="py-10 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl font-bold text-red-600 mb-4 font-poppins">
          Who should read Fijoli ..
          </h2>
          <p className="text-xl text-gray-700 max-w-3xl mx-auto font-lato">
          Everyone ! Yes.
          <p>
Student, Parents, Working Professionals, Housewives…
</p>

          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-gray-900 mb-4 font-poppins">
            Anyone looking to understand the fundamentals of fitness and nutrition
            </h3>
            <p className="text-gray-700 mb-4 font-lato">
            This book breaks down complex concepts into simple, actionable knowledge for beginners.            </p>
            {/* <ul className="space-y-2 text-gray-700 font-lato">
              <li>• Private Facebook group access</li>
              <li>• Monthly Q&A sessions</li>
              <li>• Progress sharing platform</li>
            </ul> */}
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-gray-900 mb-4 font-poppins">
            Those who have just started their fitness journey
            </h3>
            <p className="text-gray-700 mb-4 font-lato">
            Whether you are hitting the gym, starting a sport, or simply becoming more active, Fijoli is the perfect guide to help you build a strong foundation on the fundamentals.            </p>
            
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold text-gray-900 mb-4 font-poppins">
            Individuals aiming to reach their fitness goals through a structured diet            </h3>
            <p className="text-gray-700 mb-4 font-lato">
            Learn how to align your eating habits with your fitness objectives using science-backed strategies and easy-to-follow individual specific calculations.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoShouldReadFijoli; 