import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';
import logo from '../assets/logo.jpg';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Info */}
          <div>
            <img src={logo} alt="Fijoli Logo" className="h-12 mb-4" />
            <p className="text-gray-400">
              Transform your life with Fijoli - 
            <p> Your complete guide to fitness and nutrition.</p> 
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="#home" className="text-gray-400 hover:text-red-400 transition-colors">Home</a></li>
              <li><a href="#story" className="text-gray-400 hover:text-red-400 transition-colors">Story</a></li>
              <li><a href="#why-fijoli" className="text-gray-400 hover:text-red-400 transition-colors">Why Fijoli</a></li>
              <li><a href="#who-should-read-fijoli" className="text-gray-400 hover:text-red-400 transition-colors">Who Should Read Fijoli</a></li>
              <li><a href="#whats-in-book" className="text-gray-400 hover:text-red-400 transition-colors">What's in the Book</a></li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-2 text-gray-400">
              <li>Email: fitnessjourney@fijoli.com</li>
              <li>Phone: +91 9008023230</li>
              <li>Address: Bengaluru, Karnataka, India
              </li>
            </ul>
          </div>

          {/* Social Links */}
          {/* <div>
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-red-400 transition-colors">
                <FaFacebook className="w-6 h-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-red-400 transition-colors">
                <FaInstagram className="w-6 h-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-red-400 transition-colors">
                <FaTwitter className="w-6 h-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-red-400 transition-colors">
                <FaLinkedin className="w-6 h-6" />
              </a>
            </div>
          </div> */}
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Fijoli. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 