import React from 'react';
import logo from '../../assets/logo.jpg';
import heroBg from '../../assets/hero-bg3.jpg';
import { colors } from '../../config/colors';

function HeroSection() {
  return (
    <div id="home" className="relative min-h-[72vh] md:min-h-screen flex items-center justify-center">
      {/* Background Image with Overlay */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${heroBg})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
        {/* <img 
          src={logo} 
          alt="Fijoli Logo" 
          className="w-48 h-auto mx-auto mb-8 animate-fade-in"
        />
         */}
        <h1 className="text-4xl md:text-6xl font-bold mb-6 text-white font-poppins">
          <span className="border-b-4 border-red-500 leading-[1.5]
">Transform Yourself</span>
        </h1>
        <h1 className="text-4xl md:text-6xl font-bold mb-6 text-white font-poppins">
        Your <span className="text-red-500">Li</span>fe's <span className="text-red-500">Fi</span>tness <span className="text-red-500">Jo</span>urney 
        </h1>
        
        <p className="text-xl md:text-2xl text-gray-200 font-lato">
          The fitness blueprint for life ….
        </p>
        
        {/* <a
          href="https://wa.me/+919008023230"
          className="inline-block px-8 py-4 rounded-full text-lg font-semibold bg-red-600 text-white hover:bg-red-700 transition-all transform hover:scale-105 shadow-lg font-poppins"
        >
          Order Now
        </a> */}
      </div>
    </div>
  );
}

export default HeroSection; 